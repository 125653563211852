'use strict';

angular.module('enervexSalesappApp').controller('DiscussionCtrl', function($scope, $stateParams, $state, $q, _, DiscussionsService, Job, Account, User, FileUploader, Auth, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.attachments = [];
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.discussionId = $stateParams.discussionId;
	$scope.checkedEmailMembers = [];
	$scope.setChecks = false;
	$scope.me = Auth.getCurrentUser
	if ($stateParams.mode == "edit") {
		$scope.mode = "edit"
	}
	if ($scope.mode === 'edit'){
		$scope.editForm = true;
	}
	$scope.editState = function(){
		$stateParams.mode = 'edit';
		$state.go('discussion', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			discussionId: $scope.discussionId,
			mode: 'edit'
		}, {
			reload: true,
			inherit: false
		});
	}
	var idParams = {
		accountId: $stateParams.accountId,
		jobId: $stateParams.jobId
	}
	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.job = res;
		$scope.isInternalMember = Auth.isInternalMember($scope.job);
		if ($stateParams.discussionId != "new") {
			fetchComments();
			getDiscussion();
		} else {
			$scope.discussion = {
				notifyMembers:[],
				visibility: Util.defaultVisibility($scope.job),
				firstComment: {}
			}
			$scope.target = $scope.discussion;
			Util.setNotifyMembersType($scope.discussion, $scope.job);
		}

	});
	function getDiscussion(){
		DiscussionsService.getDiscussion().$promise.then(function(discussion){
			$scope.discussion = discussion;
			$scope.target = $scope.discussion;
			Util.setNotifyMembersType($scope.discussion, $scope.job);
			$scope.checkedMembers = discussion.explicitMembers;
		});
	}
	$scope.checkedMembers = [];
	$scope.change = function() {
		$scope.formChanged = true;
	}
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;

	$scope.toggleEmailCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}

	$scope.createDiscussion = function(){
		var payload = _.omit($scope.discussion, "firstComment")
		if (payload.visibility === 'explicit'){
			payload.explicitMembers = $scope.checkedMembers;
		}
		if (payload.notifyMembersType === 'all'){
			payload.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (payload.notifyMembersType === 'some') {
			payload.notifyMembers = $scope.checkedEmailMembers;
		} else if ($scope.discussion.notifyMembersType === 'none'){
			payload.notifyMembers = [];
		}

		var params = _.clone($stateParams)
		var promises = [];
		if ($scope.uploader.queue.length > 0) {
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', payload.visibility);
				promises.push(DiscussionsService.addAttachment(fd).$promise)
			});
		}
		return $q.all(promises).then(function(responses){
			$scope.discussion.firstComment.attachments = responses;
			payload.firstComment = $scope.discussion.firstComment
			DiscussionsService.addDiscussion(payload).$promise.then(function(res){
				$state.go('discussion', {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					discussionId: res._id
				}, {
					reload: true,
					inherit: false
				});
			});
		})
	}
	$scope.cancelDiscussionEdit = function(){
		// $scope.formChanged = false;
		// $scope.mode = '';
		$state.go('discussion', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			discussionId: $scope.discussionId,
		}, {
			reload: true,
			inherit: false
		});
		// getDiscussion();
	}
	$scope.deleteDiscussion = function(discussion){
		DiscussionsService.removeDiscussion(discussion).$promise.then(function(res){
			$state.go("discussions", {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				reload: true,
				inherit: false
			})
		})
	}
	$scope.saveDiscussionChange = function(discussion){
		if (discussion.visibility === 'explicit'){
			discussion.explicitMembers = $scope.checkedMembers;
		} else {
			discussion.explicitMembers = [];
		}
		DiscussionsService.updateDiscussion(discussion).$promise.then(function(res){
			// $scope.mode = '';
			$state.go('discussion', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				discussionId: $scope.discussionId,
			}, {
				reload: true,
				inherit: false
			});
			// getDiscussion();
			// $scope.formChanged = false;
		});
	}
	function fetchComments(){
		DiscussionsService.getComments().$promise.then(function(comments){
			$scope.discussionComments = comments;
		});
	}
	$scope.newComment = {
		notifyMembers:[]
	};
	Util.setNotifyMembersType($scope.newComment, $scope.job);

	var uploader = $scope.uploader = new FileUploader({});
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some') {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.discussion.visibility);
				promises.push(DiscussionsService.addAttachment(fd).$promise)
			});
			return $q.all(promises).then(function(responses){
				newComment.attachments = responses;
				DiscussionsService.saveComment(newComment).$promise.then(function(res) {
					fetchComments();
					$scope.newComment = {};
					$scope.uploader.queue = [];
				});
			})
		} else {
			return DiscussionsService.saveComment(newComment).$promise.then(function(res) {
				fetchComments();
				$scope.uploader.queue = [];
				$scope.newComment = {};
			});
		}
	}
	$scope.deleteComment = function(comment) {
		DiscussionsService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		DiscussionsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;
		DiscussionsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
});

